<form [formGroup]="form">
<app-companies [defaultValue]="defaultValue.company" (valueState)="setCompany($event)" ></app-companies>
<div class="mb-3">
    <label for="since" class="form-label">¿Desde cuando trabajas en esa empresa?</label>
    <input type="date" formControlName="since" class="form-control" id="since" aria-describedby="sinceHelp"
        (keyup)="setDate()" (change)="setDate()"
        [ngClass]="{'is-invalid': form.get('since')?.invalid && (isTimeout || form.get('since')?.touched)}" >
    <div *ngIf="form.get('since')?.invalid && (isTimeout || form.get('since')?.touched)" class="form-text">Por favor seleccione una fecha válida</div>
    <div *ngIf="(form.controls.since.errors?.pastDate || form.get('since')?.invalid) && (isTimeout || form.get('since')?.touched)"  class="form-text">
        La fecha debe ser menor que la fecha actual.
    </div>
</div>  
<div class="mb-3">
    <label for="code" class="form-label">Si tienes código de empleado por favor digitalo</label>
    <input type="text" formControlName="code" class="form-control" id="code" aria-describedby="codeHelp" (keyup)="setCode()">
</div>  
<app-caract [defaultValue]="defaultValue.caract" (valueState)="setCarac($event)"></app-caract>
<app-field-number [defaultValue]="defaultValue.income" [minValidValue]="400000"  (fieldValue)="setIncome($event)" fieldLabel="¿Cuál es tu ingreso por la actividad económica principal?"></app-field-number>
<div class="mb-3">
    <label for="codpro" class="form-label">¿Cuál es tu profesión u oficio?</label>
    <select formControlName="codpro" class="form-select" id="codpro" aria-describedby="codproHelp" (change)="changeOption()"
        [ngClass]="{'is-invalid': form.get('codpro')?.invalid && (isTimeout || form.get('codpro')?.touched)}" >
        <option value="" selected>Seleccione una opción...</option>
        <option *ngFor="let item of codpros" [value]="item.id">{{ item.name }}</option>
    </select>
    <div *ngIf="form.get('codpro')?.invalid && (isTimeout || form.get('codpro')?.touched)" class="form-text">Por favor seleccione un elemento de la lista</div>
</div>
</form>