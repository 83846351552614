import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { VideoService } from '../services/video.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  animations: [
    trigger('grow', [
      state('small', style({ transform: 'scale(1)' })),
      state('large', style({ transform: 'scale(1.2)' })),
      transition('small <=> large', animate('500ms ease-in-out'))
    ])
  ]
})
export class CardComponent implements OnInit {

  @Input() icon: string = '';
  @Input() firstLine: string = '';
  @Input() secondLine: string = '';
  @Input() link: string = '';
  @Output() stateOutput = new EventEmitter<string>();
  @Input() state: 'small' | 'large' = 'small';

  constructor(private videoService: VideoService,
              private title: Title,
              private router: Router) {
  }

  ngOnInit(): void {
    this.title.setTitle('Onboarding Digital | Servicios | ' + this.secondLine);
  }

  toggleSize() {
    if(this.link.length > 3) {
      this.state = this.state === 'small' ? 'large' : 'small';
      this.stateOutput.emit(this.state);
    }
  }
  
  goToVideo() {
    this.videoService.firstLine = this.firstLine;
    this.videoService.secondLine = this.secondLine;
    this.videoService.icon = this.icon;
    this.videoService.link = this.link;
    this.router.navigateByUrl('/app/video');
  }

}
