<div class="overlay">
</div>

<div class="centrado">
    Oops!  Parece que algo no está bien, <br>
    por favor revisa tu información...
    <br>
    <span class="small">{{ error }}</span>
    <br>
    <button class="btn btn-warning" (click)="retry()">Reintentar</button>
    <br>
    <a [href]="url" target="_blank">
        O contacta a tu asesor, clic aquí <img src="assets/whatsapp.png" alt="whatsapp" >
    </a>
    
</div>