<div class="title-container body-blue">
    <div class="back" (click)="back()" style="color: gray;">Regresar</div>
    <div class="logo-box">
      <img src="assets/logobsc.png" class="logo" alt="Logo Fincomercio" />
    </div>
  </div>
  <div class="body-container">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center title">
            ¡FELICITACIONES!
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center subtitle">
            Ya haces parte de esta gran familia
        </div>
      </div>
  
      <div class="row p-3">
        <div class="offset-md-6 col-md-5 text-end">
            <div class="row">
                <button type="button" class="btn btn-success mt-2">Descargar mi Vinculación Digital</button>
                <button type="button" class="btn btn-success mt-2">Conoce la oferta que tenemos para tí</button>
                <a type="button" class="btn btn-success mt-2" target="_blank" [href]="'https://api.whatsapp.com/send?phone=+57' + phone + '&text=Hola,%20estoy%20interesado%20en%20obtener%20más%20información.'">Comunícate con tu asesor</a>
                <a type="button" class="btn btn-success mt-2" (click)="finish()">Finalizar</a>
            </div>
        </div>
      </div>
  
    </div>
  </div>
  