import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { LocalstorageService } from '../services/localstorage.service';

@Component({
  selector: 'app-error-procesar-otro-medio',
  templateUrl: './error-procesar-otro-medio.component.html',
  styleUrls: ['./error-procesar-otro-medio.component.scss']
})
export class ErrorProcesarOtroMedioComponent implements OnInit {

  @Input() error = 'No es posible continuar con tu vinculación por este medio, por favor acércate a una oficina.';
  @Input() reload = false;
  @Output() signalRetry = new EventEmitter<boolean>();
  url: SafeUrl = '';
  phone;

  constructor(private sanitizer: DomSanitizer,
              private localStorageService: LocalstorageService
    ) {
      this.phone = this.localStorageService.get('phone');
  }

  ngOnInit(): void {

    const greating = `Hola, necesito tu ayuda para continuar con mi vinculación digital.`;
    const errorMessage = `Me sale el error: ${ this.error }. Gracias`;
    const consolidateErrorMessage = encodeURIComponent(`${greating}${errorMessage}`);
    this.url = this.sanitizer.bypassSecurityTrustUrl(`https://api.whatsapp.com/send?phone=+57${this.phone}&text=${consolidateErrorMessage}`);
  }


}
