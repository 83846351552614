<div class="container d-flex flex-column min-vh-100" style="border: red solid 1px;">
    <canvas id="canvas" style="position: fixed; top: 0; left: 0; pointer-events: none; width: 100%; height: 100%;"></canvas>
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <app-header></app-header>
        <p>main works!</p>
        <router-outlet></router-outlet>
        <br>
        Fin del Main
    </div>
</div>
<button (click)="fireConfetti()">Disparar confeti</button>
<img src="assets/treasure.png" id="treasure">
<app-footer class="mt-auto"></app-footer>
