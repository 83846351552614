import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import anime from 'animejs';
import * as confetti from 'canvas-confetti';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateCanvasSize();
  }
  count = 400;
  defaults = {
    origin: { y: 0.8 },
  };
  
  constructor() {}

  ngOnInit(): void {
    const that = this;
    this.updateCanvasSize();

  
    anime({
      targets: document.getElementById('treasure'),
      translateY: ['-100%', '0%'],
      easing: 'easeOutElastic(1.5, .4)',
      duration: 1000,
      delay: anime.stagger(100)
    });



  }

  ngOnDestroy(): void {
  }

  fire(particleRatio: any, opts: any) {
    // @ts-ignore
    const confettiInstance = confetti.create(document.getElementById('canvas'), {
      resize: true,
      useWorker: true,
    });
    
    const options = Object.assign({}, this.defaults, opts, {
      particleCount: Math.floor(this.count * particleRatio),
    });

    confettiInstance(options);
    }
  
  fireConfetti() {
    this.fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
  
    this.fire(0.2, {
      spread: 60,
    });
  
    this.fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
  
    this.fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
  
    this.fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }

  updateCanvasSize() {
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  }  
  
}