import { Component } from '@angular/core';
import { SecurityService } from '../modules/vinculaciones/services/security.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-desktop-message',
  templateUrl: './desktop-message.component.html',
  styleUrls: ['./desktop-message.component.scss'],
})
export class DesktopMessageComponent {

  qrImage;
  consultor;

  constructor(private title: Title,
              securityService: SecurityService,) {
    this.title.setTitle('Onboarding Digital | Inicio');
    securityService.consultor
      .subscribe((consultor: any) => {
        this.consultor = consultor;
      });
  }

}
