import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  icon: string = '';
  firstLine: string = '';
  secondLine: string = '';
  link: string = '';

  constructor() { }
  
}
