import { Component } from '@angular/core';

@Component({
  selector: 'app-congrats2',
  templateUrl: './congrats2.component.html',
  styleUrls: ['./congrats2.component.scss']
})
export class Congrats2Component {

}
