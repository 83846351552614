<ng-container *ngIf="consultor?.user">
    <div class="overlay">
</div>

<div class="centrado">
    <img src="assets/logobsc.png" class="logo" alt="Logo BSC ">
    <br>

    Para mejorar tu experiencia, por favor ingresa desde tu celular.
    <br>

    Escanea el siguiente código QR con tu celular.
    <br>
    <app-qr></app-qr>

</div>
</ng-container>
