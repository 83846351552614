<div class="hidden" #warning1 style="position: absolute;">
  <img src="assets/apuntador.png" alt="apuntador" class="arrow-pointer" />
  <div class="message-camera">
    NO tenemos tu permiso para acceder a la cámara. Por favor, selecciona 'Permitir' en la sección de permisos. Al presionar "Aceptar", iniciemos el reconocimiento facial. Si tienes algún inconveniente o no encuentras este permiso, intenta recargar la página o cambia de navegador.
    <br />
    <button type="button" class="btn btn-warning" (click)="onSubmit()">
      Aceptar
    </button>
  </div>
</div>
<div class="hidden" #terms style="position: absolute;">
  <div class="message-camera" style="overflow: scroll; height: 60%; font-size: 0.8em; z-index: 100000000000;">
    AQUI VA DEBERES, OBLIGACIONES Y AUTORIZACIONES.
    <br />
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet, quisquam ipsum! Minima ad nostrum unde ex excepturi nam itaque nisi, ipsa reprehenderit quibusdam sequi laudantium rerum quam, omnis aliquid perspiciatis.
    <br><br>
    Ommodi alias sapiente quia dolore quasi. Architecto quasi numquam earum suscipit placeat ratione, quibusdam laudantium necessitatibus ipsam eligendi deserunt, beatae molestiae non!
    <br><br>
    Ratione, fuga quod! Voluptatum molestiae, molestias harum laboriosam adipisci, minima unde quaerat voluptatem a quis tenetur. Delectus officiis assumenda modi asperiores consequuntur?
    <br><br>
    Consectetur adipisicing elit. Error voluptas iusto id dicta quidem voluptatum. Asperiores quis totam, tempore hic aliquam, officiis quae, temporibus consequatur ipsa ipsum distinctio quidem beatae.
    <br>
    <button type="button" class="btn btn-warning" (click)="hiddeTerms()">
      Aceptar
    </button>
  </div>
</div>
<div class="hidden" #warning0 style="position: absolute;">
  <div class="message-camera">
    Para completar el proceso sin inconvenientes, necesitaremos acceso a tu cámara. Por favor, autorízalo cuando se te solicite. ¡Gracias por tu colaboración!
    <br />
    <button type="button" class="btn btn-warning" (click)="testingCamera()">
      Continuar
    </button>
  </div>
</div>
<div class="title-container body-blue">
  <div class="back" (click)="back()">Regresar</div>
  <div class="logo-box">
    <img src="assets/logobsc.png" class="logo" alt="Logo Fincomercio" />
  </div>
</div>
<div class="body-container">
  <div class="container">
    <div class="row">
      <div class="col d-flex justify-content-center title">
        Ahora vamos a conocernos...
      </div>
    </div>

    <div class="row">
      <div class="col text-start">
        <form [formGroup]="form">
          <div class="mb-3">
            <label for="fullname" class="form-label">¿Cómo te llamas?</label>
            <input
              type="text"
              formControlName="fullname"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  (form.get('fullname')?.touched || isTimeout) &&
                  form.get('fullname')?.invalid
              }"
              id="fullname"
              aria-describedby="fullnameHelp"
            />
            <div
              *ngIf="
                (form.get('fullname')?.touched || isTimeout) &&
                form.get('fullname')?.invalid
              "
              id="fullnameHelp"
              class="form-text"
            >
              Tú nombre debe ser mayor a 3 carácteres.
            </div>
          </div>
          <div class="mb-3">
            <label for="identificationType" class="form-label"
              >Tipo Identificación</label
            >
            <select
              [ngClass]="{
                'is-invalid':
                  (form.get('identificationType')?.touched || isTimeout) &&
                  form.get('identificationType')?.invalid
              }"
              formControlName="identificationType"
              class="form-select"
              aria-label="Tipo de identificación"
            >
              <option selected>Seleccione...</option>
              <option value="1">Cédula de Ciudadanía</option>
              <option value="2">Cédula de Extranjería</option>
            </select>
            <div
              *ngIf="
                (form.get('identificationType')?.touched || isTimeout) &&
                form.get('identificationType')?.invalid
              "
              id="identificationTypeHelp"
              class="form-text"
            >
              Debes seleccionar el tipo de identificación.
            </div>
          </div>
          <div class="mb-3">
            <label for="identification" class="form-label"
              >Número de Identificación</label
            >
            <input
              [ngClass]="{
                'is-invalid':
                  (form.get('identification')?.touched || isTimeout) &&
                  form.get('identification')?.invalid
              }"
              formControlName="identification"
              type="number"
              class="form-control"
              id="identification"
              aria-describedby="identificationHelp"
            />
            <div
              *ngIf="
                (form.get('identification')?.touched || isTimeout) &&
                form.get('identification')?.invalid
              "
              id="indentificationHelp"
              class="form-text"
            >
              Debe ser un número de identificación válido sin espacios ni
              separadores de miles.
            </div>
          </div>
          <div class="mb-3">
            <label for="phone" class="form-label"
              >Número de Celular (para enviar códigos de seguridad)</label
            >
            <input
              [ngClass]="{
                'is-invalid':
                  (form.get('phone')?.touched || isTimeout) &&
                  form.get('phone')?.invalid
              }"
              formControlName="phone"
              type="number"
              class="form-control"
              id="phone"
              aria-describedby="phoneHelp"
            />
            <div
              *ngIf="
                (form.get('phone')?.touched || isTimeout) &&
                form.get('phone')?.invalid
              "
              id="phoneHelp"
              class="form-text"
            >
              El número de celular debe ser de 10 digitos sin espacios.
            </div>
          </div>
          <div class="mb-3">
            <label for="email" class="form-label"> Correo electrónico </label>
            <input
              [ngClass]="{
                'is-invalid':
                  (form.get('email')?.touched || isTimeout) &&
                  form.get('email')?.invalid
              }"
              formControlName="email"
              type="email"
              class="form-control"
              id="email"
              aria-describedby="emailHelp"
            />
            <div
              *ngIf="
                (form.get('email')?.touched || isTimeout) &&
                form.get('email')?.invalid
              "
              id="emailHelp"
              class="form-text"
            >
              Debes digitar un correo válido.
            </div>
          </div>
          <div class="mb-3">
            <label for="birthday" class="form-label">Cuándo naciste?</label>
            <input
              type="date"
              formControlName="birthday"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  (form.get('birthday')?.touched || isTimeout) &&
                  form.get('birthday')?.invalid
              }"
              id="birthday"
              aria-describedby="brithdayHelp"
            />
            <div
              *ngIf="
                (form.get('birthday')?.touched || isTimeout) &&
                form.get('birthday')?.invalid
              "
              id="birthdayHelp"
              class="form-text"
            >
              La fecha de nacimiento es requerida y debe ser válida.
            </div>
          </div>
          <app-locations
            [disabled]="hasValidationPerson"
            [label]="'¿Dónde naciste?'"
            [defaultValue]="form.get('cityBorn')!.value"
            (valueState)="setLocation($event)"
          ></app-locations>
          <div class="mb-3 form-check">
            <input
              [ngClass]="{
                'is-invalid':
                  (form.get('authorization')?.touched || isTimeout) &&
                  form.get('authorization')?.invalid
              }"
              formControlName="authorization"
              type="checkbox"
              class="form-check-input"
              id="terms"
            />
            <label class="form-check-label" for="terms" (click)="showTerms()"
              >Acepto términos y condiciones</label
            >
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="!canContinue">
      No es posible continuar con su vinculación por este canal. Por acérquese a
      una oficina o comuníquese con su asesor. Error 122.
    </div>

    <div>
      <button
        type="button"
        [disabled]="form.invalid || isLoading || !canContinue"
        class="btn btn-success"
        (click)="showPreAlertCamera()"
      >
        Continuar
      </button>
      <br />
      <!-- <button (click)="skip()" [disabled]="form.invalid" class="btn btn-danger my-3">SALTAR SANDBOX FUERA DE LÍNEA</button> -->
    </div>
  </div>
</div>
<app-waiting *ngIf="isLoading"></app-waiting>
<app-error
  *ngIf="errorMessage"
  [reload]="reload"
  [error]="errorMessage"
  (signalRetry)="retry()"
></app-error>
<app-wow *ngIf="wowDisplay" (completed)="next()"></app-wow>
