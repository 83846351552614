<div id="overlay" class="overlay"></div>
<div class="imagen-centrada" >
    <div id="bg-white"></div>
</div>
<div class="imagen-centrada">
    <div style="text-align: center; ">
        <span class="principal">
            FELICITACIONES
        </span>
        <br>
        <br>
        <img id="prize" class="img-prize" src="assets/trofeo.png" alt="Descripción de la imagen">
        <br>
        <div class="message">
            <div class="line-text">
                <span>¡CONTINÚA <br> AVANZANDO!</span>
              </div>
        </div>
        <button class="btn btn-warning" (click)="finish()">Aceptar</button>
    </div>
</div>
