import { EnrollmentSDK } from "@soyyo/sdk_web_enrollment";
import { environmentSDK } from "../../../../environments/environmentSDK.development";
import { environmentTransactions } from "../../../../environments/environmentTramites.development";
import { AuthenticationSDK } from "@soyyo/sdk_web_authentication";
import { TramitesSDK } from "@soyyo/sdk_web_transactions";
import { BehaviorSubject } from "rxjs";

interface dataInterface {
    appIdentifier: string;
    channel: any;
    email: string;
    entityId: string;
    phoneIndicative: string;
    phoneNumber: string;
    processType: string;
    documentType: any;
    identificationNumber: string;
    otpInternal: boolean;
}

export const LEVELS = {
    'NO_IDENTITY': '0',   // SIN NIVEL DE IDENTIDAD
    'BASIC': '1',   // NO QUEDA SEGURO DE LA VERIFICACION, LLAMAR A SOPORTE O ALGO
    'ESSENTIAL': '2', // QUEDA PERFECTO
}

export const AUTHENTICATED_USER_CODES = {
    AUTHENTICATED_SUCCESS: 'AP001',
    MAX_INTENT_TRIES: 'AP002',
    USER_NOT_MATCH: 'AP003',
}

export const ENROLLMENT_REGISTER_DATA_CODES = {
    SUCCESS: 'EP001',
    INCORRECT_DATA: 'EP002',
    USER_ALREADY_REGISTER: 'EP003',
}

export const ENROLLMENT_BIOMETRIC_PHOTO = {
  SUCCESS: 'EP004',
  FAIL_PHOTO: 'EP005',
  INSUFICIENT_VALIDATION: 'EP006',
  MAX_INTENT_TRIES: 'EP007',
}

export const ENROLLMENT_DOCUMENT_VALIDATE_CODES = {
  SUCCESS: 'EP001',
  FAIL_PROCESS_CAPTURE: 'EP003',
  MAX_INTENT_TRIES: 'EP007',
}

export const ENROLLMENT_USER_ACTIVATION = {
    SUCCESS: 'EP001',
    FAIL: 'EP003',
    PENDING: 'EP007',
}

export const TRAMITES_CODES = {
    SUCCESS: 'TRX001',
    INCOMPLETED: 'TRX002',
    REJECTED: 'TRX003',
}

export const OTP_CODES = {
    SUCCESS: 'EXN001',
    FAIL: 'EXN002',
    MAX_INTENT_TRIES: 'EXN003',
}

export class SoyYoService {

    private appIdentifier;
    private channel = 'WEB_CLIENT';
    private entityId;
    private procedureId;
    private enrollment!: EnrollmentSDK;
    private authentication;
    private tramitesSDK;
    private sessionToken;
    private processId = null;
    private transactionId;
    private token = null;
    private tokenTramites = null;
    private _isAuthenticatedUser: boolean = false;
    private _isAuthenticatedUserData;
    private _isCustomerCreated: boolean = false;
    private _isCustomerCreatedData: any;
    private _isUserRegisterSuccess;
    private _isUserRegisterData;
    private _isCaptureBiometricFaceSuccess;
    private _captureBiometricFaceData;
    private _isDocumentValidated;
    private _documentValidateData;
    private _isActivatedUser;
    private _activatedUserData;
    private _isTransactionCreated;
    private _isTransactionCreatedData;
    private _isTramitesAuthorizationGetInformation;
    private _tramitesAuthorizationGetInformationData;
    private _isListInformationGetting;
    private _listInformationData;
    public errorTrhowed = new BehaviorSubject<boolean>(false);
  
    constructor() {
      this.entityId = Number(environmentSDK.ENTITY_ID);
      this.procedureId = environmentSDK.PROCEDURE_ID;
      this.appIdentifier =environmentSDK.APP_IDENTIFIER;
    }
    
    async getToken() {
      // console.log('***', 'getToken()')
      let endpoint = environmentSDK.ENDPOINT_AUTH;  
    try {
      const response = await fetch(
        `${endpoint}?client_id=${environmentSDK.CLIENT_ID}&client_secret=${environmentSDK.CLIENT_SECRET}&grant_type=client_credentials`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-api-key': environmentSDK.API_KEY,
          },
        },
      );
      const data = await response.json()
      return data.access_token;
    } catch (e) {
      console.error(e);
      return '';
    }
  }

  async getTokenTramites() {
    // console.log('***', 'getTokenTramites()')
    let endpoint = environmentSDK.ENDPOINT_AUTH;  
    try {
      const response = await fetch(
        `${endpoint}?client_id=${environmentTransactions.CLIENT_ID}&client_secret=${environmentTransactions.CLIENT_SECRET}&grant_type=client_credentials`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-api-key': environmentTransactions.API_KEY,
          },
        },
      );
      const data = await response.json()
      return data.access_token;
    } catch (e) {
      console.error(e);
      return '';
    }
  }


  isCustomerRegistred() {
    // console.log('***', 'isCustomerRegistred()', this._isCustomerCreated)
    return this._isCustomerCreated;
  }

  isUserAuthenticated() {
    // console.log('***', 'isUserAuthenticated()', this._isAuthenticatedUser)
      return this._isAuthenticatedUser;
  }

  getAuthenticatedCode() {
    // console.log('***', 'getAuthenticatedCode()', this._isAuthenticatedUserData?.code)

      return this._isAuthenticatedUserData?.code;
  }

  getAuthenticatedUserData() {
    // console.log('***', 'getAuthenticatedCode()', this._isAuthenticatedUserData?.code)

      return this._isAuthenticatedUserData;
  }

  isUserRegisterSuccess() {
    // console.log('***', 'isUserRegisterSuccess()', this._isUserRegisterSuccess)

      return this._isUserRegisterSuccess;
  }

  getUserRegisterDataCode() {
    // console.log('***', 'getUserRegisterDataCode()', this._isUserRegisterData?.code)

      return this._isUserRegisterData?.code;
  }

  isCaptureBiometricFaceSuccess() {
    // console.log('***', 'isCaptureBiometricFaceSuccess()', this._isCaptureBiometricFaceSuccess)

      return this._isCaptureBiometricFaceSuccess;
  }

  getCaptureBiometricFaceData() {
    // console.log('***', 'getCaptureBiometricFaceData()', this._captureBiometricFaceData.code)

      return this._captureBiometricFaceData.code;
  }

  isTransactionCreated() {
    // console.log('***', 'isTransactionCreated()', this._isTransactionCreated)

    return this._isTransactionCreated;
  }

  getTransactionCreatedData() {
    // console.log('***', 'getTransactionCreatedData()', this._isTransactionCreatedData)

      return this._isTransactionCreatedData;
  }

  isTramitesAuthorizationGetInformation() {
    // console.log('***', 'isTramitesAuthorizationGetInformation()', this._isTramitesAuthorizationGetInformation)

      return this._isTramitesAuthorizationGetInformation;
  }

  getTramitesAuthorizationGetInformation() {
    // console.log('***', 'getTramitesAuthorizationGetInformation()', this._tramitesAuthorizationGetInformationData)

        return this._tramitesAuthorizationGetInformationData;
  }

  isListInformationGetting() {
    // console.log('***', 'isListInformationGetting()', this._isListInformationGetting)

      return this._isListInformationGetting;
  }

  getListInformationData() {
      // console.log('***', 'getListInformationData()', this._listInformationData)

      return this._listInformationData;
  }

  isActivatedUser() {
    // console.log('***', 'isActivatedUser()', this._isActivatedUser)

      return this._isActivatedUser;
  }

  getActivatedUserData() {
    // console.log('***', 'getActivatedUserData()', this._activatedUserData)

      return this._activatedUserData;
  }

  isDocumentValidated() {
    // console.log('***', 'isDocumentValidated()', this._isDocumentValidated)

      return this._isDocumentValidated;
  }

  getDocumentValidatedData() {
    // console.log('***', 'getDocumentValidatedData()', this._documentValidateData)

      return this._documentValidateData;
  }

  async init() {
    // console.log('***', 'init()')
    if(!this.token) {
        this.token = await this.getToken();
        this.tokenTramites = await this.getTokenTramites();
      }
  }

  private getDataTransactions() {
    return new Promise((resolve, reject) => {
      const checker = setInterval(async () => {
        let endpoint = environmentTransactions.ENDPOINT_TRAMITES;
            const listasResponsePre: any = await fetch(
          `${ endpoint }${ this.transactionId }`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': environmentTransactions.API_KEY,
              Authorization: `Bearer ${this.tokenTramites}`,
            },
          },
        );
        // console.log('***', 'ejecutado el GET');
        // TODO: SI CONTESTA QUE ES 1 VUELVO A LANZAR LA PETICION HASTA QUE SEA 2. "transactionValidationStatusId": 1,
        const listasResponse = await listasResponsePre.json();
        // console.log('***', 'transactionValidationStatusId', listasResponse.data.transactionValidation[0].transactionValidationStatusId);
        if(listasResponse.data.transactionValidation[0].transactionValidationStatusId === 2) {
          clearInterval(checker);
          resolve(listasResponse);
        }
      }, 3000);
    });
  }

  async getTramitesListInformation(): Promise<any> {
    // console.log('***', 'getTramitesListInformation()')
    try {
      const listasResponse: any = await this.getDataTransactions();
      // console.log('***', 'listasResponse', listasResponse);
      // console.log('***', 'transactionValidationStatusId', listasResponse.data.transactionValidation[0].transactionValidationStatusId);
      this._isListInformationGetting = true;
      this._listInformationData = listasResponse;
    } catch (error) {
        console.error(error);
        this._isListInformationGetting = false;
        this._listInformationData = error;
      }
  }

  async checkTramitesAuthorizationGetInformation(): Promise<any> {
    // console.log('***', 'checkTramitesAuthorizationGetInformation()')

    var getTransaction: any = {
      sessionToken: this.sessionToken,
      transactionId: this.transactionId,
    };
    // console.log("getTransactions", getTransaction);
    return new Promise((resolve, reject) => {
      this.tramitesSDK = new TramitesSDK(environmentTransactions, (err) => {
        // console.log('new TramitesSDK', err, environmentTramites);
      });
      this.tramitesSDK.authorization(getTransaction, async (response) => {
        // console.log(response.code);
          switch(response.code) {
              case TRAMITES_CODES.SUCCESS:
                this._isTramitesAuthorizationGetInformation = true;
                break;
              // @ts-ignore: fallthrough
              case TRAMITES_CODES.INCOMPLETED:
                // console.log('TRAMITES_CODES.INCOMPLETED');
              case TRAMITES_CODES.REJECTED:
              default:
                this._isTramitesAuthorizationGetInformation = false;
                break;
          }
        this._tramitesAuthorizationGetInformationData = response;
        resolve(true);
      },
        (error) => {
            this._isTramitesAuthorizationGetInformation = false;
            this._tramitesAuthorizationGetInformationData = error;
            resolve(false);
        });


    });

    // crear la transaccion
    // https://api.soyyo.mobi/snb-transaction/transaction/v2.1/transactions

    //consumir la transaccion
    // https://api.soyyo.mobi/snb-transaction/transaction/v2.1/transactions/:idTransaction GET


    // https://dominio/ambiente-api/transaction/v2.1/transactions
    // https://api.soyyo.mobi/snb-enrollment-process/transaction/v2.1/transactions

  }

  async createTransaction(documentType = 'CC', identification): Promise<any> {
    // console.log('***', 'createTransaction()')

    const data = {
      documentType: documentType,
      identificationNumber: identification?.toString(),
      procedureId: this.procedureId,
      channel: this.channel,
      entityId: this.entityId,
    };
    return new Promise(async (resolve, reject) => {
      try {
        let endpoint = environmentTransactions.ENDPOINT_TRAMITES;
        const response: any = await fetch(
          `${ endpoint }`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': environmentTransactions.API_KEY,
              Authorization: `Bearer ${this.tokenTramites}`,
            },
            body: JSON.stringify(data),
          },
        );
        // console.log(response);
        const resp1 = await response.json();
        // console.log('resp1', resp1);
        this._isTransactionCreated = true;
        this._isTransactionCreatedData = resp1;
        this.transactionId = parseInt(resp1?.data?.qr?.transactionId, 10);
        resolve(true);
      } catch (error) {
        this._isTransactionCreated = false;
        this._isTransactionCreatedData = error;
        resolve(false);
      }
    });
  }

  async enrollmentDocumentValidate(email, phone, documentType, identification): Promise<any> {
    // console.log('***', 'enrollmentDocumentValidate()')
    let data: dataInterface = {
      appIdentifier: this.appIdentifier,
      channel: this.channel,
      email,
      entityId: this.entityId.toString(),
      phoneIndicative: '57',
      phoneNumber: phone?.toString(),
      processType: 'ENR',
      documentType: documentType,
      identificationNumber: identification?.toString(),
      otpInternal: true,
    };
    return new Promise((resolve, reject) => {
      this.enrollment.documentValidate(
        {
          ...data,
          processId: this.processId ? parseInt(this.processId, 10): 0,
          processType: data.processType,
          channel: data.channel,
          entityId: data.entityId,
        },
        async (res) => {
            // console.log("ENROLLMENT DOCUMENT CODES REVISAR", res.code);
            switch(res.code) {
                case ENROLLMENT_DOCUMENT_VALIDATE_CODES.SUCCESS:
                    this._isDocumentValidated = true;
                    break;
                case ENROLLMENT_DOCUMENT_VALIDATE_CODES.FAIL_PROCESS_CAPTURE:
                case ENROLLMENT_DOCUMENT_VALIDATE_CODES.MAX_INTENT_TRIES:
                default:
                    this._isDocumentValidated = false;
                    break;
            }
          this._documentValidateData = res;
          resolve(true);
        },
        (error: any) => {
            this._isDocumentValidated = false;
            this._documentValidateData = error;
            resolve(false);
          }
      );
    });

  }

  async activatedUser(): Promise<any> {
    // console.log('***', 'activatedUser()')

    return new Promise(async (resolve, reject) => {
      try {
        let endpoint = environmentSDK.ENDPOINT_ACTIVATION;
        const responseActivation = await fetch(
          `${endpoint}/${this.processId}/1`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': environmentSDK.API_KEY,
              Authorization: `Bearer ${this.token}`,
            },
          },
        );
        // console.log('responseActivation', responseActivation);
        const activation = await responseActivation.json();
        // console.log('activation', activation)
        switch(activation.code) {
            case ENROLLMENT_USER_ACTIVATION.SUCCESS:
              // todo: tumbar el proceso y volver a empezar????
              // marcar como un caso especial, porque no cumple el nivel de identidad
              
              break;
            case AUTHENTICATED_USER_CODES.AUTHENTICATED_SUCCESS:
                this._isActivatedUser = true;
                this._isAuthenticatedUser = true;
                this.sessionToken = activation.data.sessionToken;
                break;
            case AUTHENTICATED_USER_CODES.MAX_INTENT_TRIES:
            case AUTHENTICATED_USER_CODES.USER_NOT_MATCH:
            case ENROLLMENT_USER_ACTIVATION.FAIL:
            case ENROLLMENT_USER_ACTIVATION.PENDING:
            default:
                this._isActivatedUser = false;
                break;
        }
        this._activatedUserData = activation;
        resolve(true);
      } catch (error) {
        this._isActivatedUser = false; 
        this._activatedUserData = error;
        resolve(false);
      }
    });
  }


  async captureFaceProcess(email, phone, documentType = 'CC', identification): Promise<any> {
    // console.log('***', 'captureFaceProcess()')

    let data: dataInterface = {
      appIdentifier: this.appIdentifier,
      channel: this.channel,
      email,
      entityId: this.entityId.toString(),
      phoneIndicative: '57',
      phoneNumber: phone?.toString(),
      processType: 'ENR',
      documentType: documentType,
      identificationNumber: identification?.toString(),
      otpInternal: true,
    };
    return new Promise((resolve, reject) => {
      this.enrollment.captureFace({
        ...data,
        processId: this.processId ? parseInt(this.processId, 10): 0,
        processType: data.processType,
        channel: data.channel,
        entityId: data.entityId,
      },
        (response) => {
          // console.log('Mensaje capture Face: ', response);
          switch (response.liveness.code) {
            case ENROLLMENT_REGISTER_DATA_CODES.SUCCESS:
            case ENROLLMENT_BIOMETRIC_PHOTO.SUCCESS:
                this._isCaptureBiometricFaceSuccess = true;
                break;
            case ENROLLMENT_BIOMETRIC_PHOTO.INSUFICIENT_VALIDATION:
            case ENROLLMENT_BIOMETRIC_PHOTO.MAX_INTENT_TRIES:
            case ENROLLMENT_BIOMETRIC_PHOTO.FAIL_PHOTO:
                    this._isCaptureBiometricFaceSuccess = false;
                break;
            default:
                this._isCaptureBiometricFaceSuccess = false;
                break;
          }
          this._captureBiometricFaceData = response.liveness;
          resolve(true);
        },
        (error) => {
          console.log('Mensaje capture Face: ', error);
          this._isCaptureBiometricFaceSuccess = false;
          this._captureBiometricFaceData = error;
          resolve(false);
        }
      );

    });

  }

  async registerUser(email, phone, documentType = "CC", identification): Promise<any> {
    // console.log('***', 'registerUser()')

    try {
      let data: dataInterface = {
        appIdentifier: this.appIdentifier,
        channel: this.channel,
        email,
        entityId: this.entityId.toString(),
        phoneIndicative: '57',
        phoneNumber: phone?.toString(),
        processType: 'ENR',
        documentType,
        identificationNumber: identification?.toString(),
        otpInternal: true,
      };
      this.enrollment = new EnrollmentSDK(environmentSDK);
      // console.log('Antes de llamar', data)
      return new Promise(async (resolve, reject) => {
        await this.enrollment.basicDataRegister(data,
          async success => {
            // console.log("REVISAR QUE EL CODIGO CORRESPONDA A ENROLLMENT_REGISTER RESPONSE", success);
            // console.log("REVISAR QUE EL CODIGO CORRESPONDA A ENROLLMENT_REGISTER CODE", success.code);
            switch(success.code) {
                  case ENROLLMENT_REGISTER_DATA_CODES.SUCCESS:
                    this._isUserRegisterSuccess = true;
                    break;
                  case ENROLLMENT_REGISTER_DATA_CODES.INCORRECT_DATA:
                  case ENROLLMENT_REGISTER_DATA_CODES.USER_ALREADY_REGISTER:
                  default:
                    this._isUserRegisterSuccess = false;
                    break;
              }
            this._isUserRegisterData = success;
            this.processId = success.data["processId"];
            resolve(true);
          },
          async error => {
            this._isUserRegisterSuccess = false;
            this._isUserRegisterData = error;
            // console.log("REVISAR POR QUE FALLÓ EL REGISTRO DEL USUARIO A", error);
            this.errorTrhowed.next(true);
            throw new Error('EL OTP ESTA MAL');
            resolve(false);
          });
      });
    } catch (error) {
        this._isUserRegisterSuccess = false;
        this._isUserRegisterData = error;
        // console.log("REVISAR POR QUE FALLÓ EL REGISTRO DEL USUARIO B", error)
    }
  }

  async authenticateUser(documentType = 'CC', identification: number): Promise<any> {
    // console.log('***', 'authenticateUser()')

    this.authentication = new AuthenticationSDK(environmentSDK);
    const data = {
      documentType: documentType,
      identificationNumber: identification?.toString(),
      appIdentifier: this.appIdentifier,
      channel: this.channel,
      entityId: this.entityId,
      otpInternal: true,
    };
    // console.log(data);
    return new Promise(async (resolve, rejects) => {
      try {
        await this.authentication.authenticate(
          data,
          (success) => {
            // console.log('success', success);
            this.sessionToken = success.data.sessionToken; // TODO: AQUI ESTA EL SESSION TOKEN
            if (success.code === AUTHENTICATED_USER_CODES.AUTHENTICATED_SUCCESS) {
              // Operación exitosa
              this._isAuthenticatedUser = true;
              this._isAuthenticatedUserData = success.data;
              resolve(true);
            }
            else {
              // console.log("REVISAR EL CODIGO DE ERROR0 POR ESTE LADO, AUTENTICACION:", success.data)  
              // Operación cancelada
              this._isAuthenticatedUser = false;
              this._isAuthenticatedUserData = success.data;
              resolve(false);
            }
          },
          (error) => {
            // console.log("REVISAR EL ERROR1 POR ESTE LADO, AUTENTICACION:", error?.code);
            // console.log("REVISAR EL ERROR1 POR ESTE LADO, AUTENTICACION:", error?.data);
            // console.log("REVISAR EL ERROR1 POR ESTE LADO, AUTENTICACION:", error?.data?.description);
            // console.log("REVISAR EL ERROR1 POR ESTE LADO, AUTENTICACION:", error?.data?.code);
            // console.log(error?.data?.description === "No se puede abrir la cámara, dé permiso o reintente la captura");
            // console.log('error.data', error.data)
            this._isAuthenticatedUser = false;
            this._isAuthenticatedUserData = error;
            resolve(false);
          });
      } catch (error) {
        // console.log("REVISAR EL ERROR2 POR ESTE LADO, AUTENTICACION:", error)  
        this._isAuthenticatedUser = false;
        this._isAuthenticatedUserData = error;
        resolve(false);
  }
    });
  }


  async checkingCustomerRegister(documentType: string = 'CC', identificationNumber: number): Promise<any> {
    // console.log('***', 'checkingCustomerRegister()')

    // console.log('environments', environments);
    let data = {
      documentType: documentType,
      identificationNumber: identificationNumber?.toString()
    };
    // console.log(data);
    try {
      let endpoint = environmentSDK.ENDPOINT_VALIDATION;
      const response: any = await fetch(
        `${ endpoint}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': environmentSDK.API_KEY,
            Authorization: `Bearer ${this.token}`,
          },
          body: JSON.stringify(data),
        },
      );
      // console.log(response);
      const resp1 = await response.json();
      // console.log('checkingCustomerRegister', resp1);

      const FOUND_USER_CODE = 'F070';
      resp1?.data?.processId ? this.processId = resp1?.data?.processId: undefined;
      this._isCustomerCreated = resp1.code === FOUND_USER_CODE;
      this._isCustomerCreatedData = resp1?.data;      // PROCESS ID, NIVEL IDENTIDAD, USER ID

        // AQUI SE VERIFICA SI ESTA EN BASICA --- NO VA A REGISTRAR ESO ES LE NIVEL DE INDENTIDAD, BASICA ESENCIAL O NO REGISTRADO
        // TODO: SIII VAAA
      // console.log("CHEQUEAR ESTA ESTRUCTURA DE DATA PARA EL CODIGO:", resp1?.data);
    } catch (error) {
      console.error(error);
      this._isCustomerCreated = false;
      this._isCustomerCreatedData = null;
    }
  }

  delay(ms) {
    return new Promise(res => setTimeout(res, ms));
  }

}