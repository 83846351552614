<ng-container *ngIf="consultor?.user">
  <div class="header-custom-container body-blue">
    <img src="assets/logobsc.png" class="logo" alt="Logo Fincomercio" />
    <div class="title">VINCULATE</div>
    <div class="subtitle">
      BSC COLOMBIA
    </div>
  </div>
  <div class="custom-container">
    <div class="text">
      ¡Acumula puntos desde ya y accede a un mundo de beneficios!
    </div>
    <div class="text">
      Durante tu proceso de vinculación validaremos tu identidad con nuestro
      aliado SoyYo. Recibirás mensajes para acompañarte durante el proceso.
    </div>
    <div class="text">
      Si estás listo para comenzar tu proceso de vinculación, por favor presiona
      el botón "Iniciar" a continuación.
    </div>
    <div>
      <button type="button" class="btn btn-success" (click)="next()">
        Iniciar
      </button>
    </div>
    <app-qr [consultor]="consultor"></app-qr>
  </div>
  <!-- <button (click)="fireConfetti()">Disparar confeti</button> -->
  <!-- <img src="assets/treasure.png" id="treasure"> -->
  <!-- <app-footer class="mt-auto"></app-footer> -->
</ng-container>

<ng-container *ngIf="!consultor?.user">
  <div class="header-custom-container body-blue">
    <img src="assets/logobsc.png" class="logo" alt="Logo Fincomercio" />
    <div class="title">VINCULATE</div>
    <div class="subtitle">
      BSC COLOMBIA
    </div>
  </div>
  <div class="custom-container">
    <div class="text">
      Por favor solicita a tu Asesor Comercial el link o su QR para poder
      continuar con tu proceso de vinculación
    </div>
    <div class="text">
      <a href="https://bsccolombia.com">Volver a bsccolombia.com</a>
    </div>
  </div>
</ng-container>
