import { Component } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { VideoService } from '../services/video.service';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent {

  videoUrl: SafeUrl;
  icon: string = '';
  firstLine: string = '';
  secondLine: string = '';

  constructor(private domSanitazer: DomSanitizer,
              private videoService: VideoService,
    ) {
    this.icon = this.videoService.icon;
    this.firstLine = this.videoService.firstLine;
    this.secondLine = this.videoService.secondLine;
    const myUrl = `https://www.youtube.com/embed/${this.videoService.link}`;
    this.videoUrl = this.domSanitazer.bypassSecurityTrustResourceUrl(myUrl);
  }

}
