<div class="title-container body-blue">
  <div class="back" routerLink="/app/services">Regresar</div>
  <img src="assets/logobsc.png" class="logo" alt="Logo Fincomercio" />
</div>
<div class="body-container">
  <div class="container text-center">
    <div class="row">
      <div class="col d-flex justify-content-center">
        <app-card
          [icon]="icon"
          [firstLine]="firstLine"
          [secondLine]="secondLine"
        ></app-card>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <iframe *ngIf="videoUrl" width="560" height="315" [src]="videoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen autoplay></iframe>
      </div>
    </div>

    <div>
      <button
        type="button"
        class="btn btn-success"
        routerLink="/app/services"
        style="margin-top: 2em"
      >
        Continuar
      </button>
    </div>
  </div>
</div>
