<div class="title-container body-blue">
    <div class="back" (click)="back()">Regresar</div>
    <div class="logo-box">
      <img src="assets/logobsc.png" class="logo" alt="Logo Fincomercio" />
    </div>
  </div>
  <div class="body-container">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center title">
            ¡Gana más puntos!
        </div>
      </div>
  
      <div class="row">
        <div class="col text-start">
                    <form>
                      <app-field-number [minValidValue]="0" [defaultValue]="form.get('incomeExtraActivity')!.value" fieldLabel="Otros Ingresos" (fieldValue)="setIncomeExtraActivity($event)"></app-field-number>
                      <app-field-number [minValidValue]="0" [defaultValue]="form.get('outcome')!.value" fieldLabel="Egresos" (fieldValue)="setOutcome($event)"></app-field-number>
                      <app-field-number [minValidValue]="400000" [defaultValue]="form.get('assets')!.value" fieldLabel="Activos" (fieldValue)="setAssets($event)"></app-field-number>
                      <app-field-number [minValidValue]="100000" [defaultValue]="form.get('liabilities')!.value" fieldLabel="Pasivos" (fieldValue)="setLiabilities($event)"></app-field-number>
                    </form>
        </div>
      </div>
  
      <div>
        <button
          type="button"
          [disabled]="form.invalid"
          class="btn btn-success"
          (click)="saveData()"
          style="margin-top: 2em"
        >
          Continuar
        </button>
      </div>
    </div>
  </div>
  <app-wow *ngIf="wowDisplay" (completed)="next()"></app-wow>