<div class="title-container body-blue">
    <div class="back" (click)="back()">Regresar</div>
    <div class="logo-box">
      <img src="assets/logobsc.png" class="logo" alt="Logo Fincomercio" />
    </div>
  </div>
  <div class="body-container">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center title">
            Algo más de información...
        </div>
      </div>
  
      <div class="row">
        <div class="col text-start">
                    <form [formGroup]="form">
                        <div class="mb-3">
                          <label for="ocupation" class="form-label">¿Cuál es tu ocupación?</label>
                          <select formControlName="asotip" class="form-select" aria-label="Ocupación" id="ocupation" (change)="changeOption()"
                              [ngClass]="{'is-invalid': form.get('asotip')?.invalid && (isTimeout || form.get('asotip')?.touched)}" >
                            <option value="" selected>Seleccione...</option>
                            <option *ngFor="let item of asotips" [value]="item.id">{{item.codefixed}}</option>
                          </select>
                          <div *ngIf="form.get('asotip')?.invalid && (isTimeout || form.get('asotip')?.touched)" class="form-text">Por favor seleccione un elemento de la lista</div>
                        </div>
                        <app-independient [defaultValue]="addressData" (formValues)="setChildValues($event)" *ngIf="form.value.asotip == 1"></app-independient>
                        <app-aggrement [defaultValue]="addressData" (formValues)="setChildValues($event)" *ngIf="form.value.asotip == 2"></app-aggrement>
                        <app-pensioner [defaultValue]="addressData" (formValues)="setChildValues($event)" *ngIf="form.value.asotip == 3"></app-pensioner>
                        <app-studient [defaultValue]="addressData.income" (formValues)="setChildValues($event)" *ngIf="form.value.asotip == 4"></app-studient>
                        
                        <app-locations [defaultValue]="addressData.location" (valueState)="setLocation($event)" [resetTimer]="resetTimer"></app-locations>
                        <div class="mb-3">
                            <label for="address" class="form-label">Dirección de Domicilio</label>
                            <input formControlName="address" type="text" class="form-control" id="address" aria-describedby="addressHelp" (keyup)="setAddress()"
                              [ngClass]="{'is-invalid': form.get('address')?.invalid && (isTimeout || form.get('address')?.touched)}" >
                            <div *ngIf="form.get('address')?.invalid && (isTimeout || form.get('address')?.touched)" class="form-text">Por favor escriba una dirección válida mayor a 8 carácteres</div>
                          </div>  
                        <div class="mb-3">
                            <label for="neighborhood" class="form-label">Barrio Domicilio</label>
                            <input formControlName="neighborhood" type="text" class="form-control" id="neighborhood" aria-describedby="neighborhoodHelp" (keyup)="setNeighborhood()"
                              [ngClass]="{'is-invalid': form.get('neighborhood')?.invalid && (isTimeout || form.get('neighborhood')?.touched)}" >
                            <div *ngIf="form.get('neighborhood')?.invalid && (isTimeout || form.get('neighborhood')?.touched)" class="form-text">Por ingrese un barrio mayor a 4 caracteres</div>
                        </div>  
                    </form>
        </div>
      </div>
  
      <div>
        <button
          type="button"
          [disabled]="!allValid"
          class="btn btn-success"
          (click)="saveData()"
          style="margin-top: 2em"
        >
          Continuar
        </button>
      </div>
    </div>
  </div>
  
  <app-wow *ngIf="wowDisplay" (completed)="next()"></app-wow>