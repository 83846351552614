import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListService } from '../../services/list.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { beforeDateValidator } from '../../validators/before-date.validator';

@Component({
  selector: 'app-pensioner',
  templateUrl: './pensioner.component.html',
  styleUrls: ['./pensioner.component.scss']
})
export class PensionerComponent implements OnInit {

  form: FormGroup;
  codpros: any[] = [];
  timer: any;
  isTimeout = false;
  @Output() formValues = new EventEmitter();
  @Input() defaultValue: any = null;

  constructor(private listService: ListService) {
    this.form = new FormGroup({
      fonpen: new FormControl('', [Validators.required]),
      resolution: new FormControl('', []),
      since: new FormControl('', [Validators.required, beforeDateValidator()]),
      codpro: new FormControl('', [Validators.required]),
      income: new FormControl('', [Validators.required]),
    });
    this.timer = setTimeout(() => {
      this.isTimeout = true;
    }, 5000);
   }

  ngOnInit(): void {
      this.listService.getCodpro()
      .subscribe({
        next: (response: any) => {
          this.codpros = response.list;
          if(this.defaultValue.codpro) {
            this.form.get('codpro')!.setValue(this.defaultValue.codpro);
          }
          if(this.defaultValue.fonpen) {
            this.form.get('fonpen')!.setValue(this.defaultValue.fonpen);
          }
          if(this.defaultValue.resolution) {
            this.form.get('resolution')!.setValue(this.defaultValue.resolution);
          }
          if(this.defaultValue.since) {
            this.form.get('since')!.setValue(this.defaultValue.since);
          }
          if(this.defaultValue.income) {
            this.form.get('income')!.setValue(this.defaultValue.income);
          }
          this.emitForm();
            
        }
      });
  }

  setFonpen(fonpenId) {
    // console.log('fonpenId', fonpenId);
    this.form.get('fonpen')!.setValue(fonpenId);
    this.emitForm();
  }

  setIncome(income) {
    this.form.get('income')!.setValue(income);
    this.emitForm();
  }

  emitForm() {
    // console.log(this.form.value);
    const formValues = this.form.value;
    Object.keys(formValues).forEach(key => {
      if(formValues[key] === '') {
        formValues[key] = null;
      }
    });
    // console.log(formValues);
    this.formValues.emit(formValues);
  }

}
