export const environmentSDK =  {
  API_KEY: 'PBxc0p3bsb4E2gqSzx29oDwaBgQPFM377ASwIBic',
  CLIENT_ID: 'qcfc67nhdu527j5qc3cbdlqfv',
  CLIENT_SECRET: '121lraeo9knq3h9lgg88h91ipojdtmpjbis8muro8fe6ao1ktfrm',
  PRODUCTION: false,
  ENTITY_ID: '67',
  PROCEDURE_ID: 170,
  APP_IDENTIFIER: 'bsccolombia.com',
  ENDPOINT_AUTH:'https://soyyo-snb.auth.us-east-1.amazoncognito.com/oauth2/token',
  ENDPOINT_ACTIVATION: 'https://api.soyyo.mobi/snb-enrollment-process/enrollment-process/v1.0/userActivation/WEB_CLIENT',
  ENDPOINT_VALIDATION: 'https://api.soyyo.mobi/snb-user/user/v2.1/users/validate',
}
