import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AUTHENTICATED_USER_CODES, ENROLLMENT_BIOMETRIC_PHOTO, ENROLLMENT_DOCUMENT_VALIDATE_CODES, ENROLLMENT_REGISTER_DATA_CODES, SoyYoService } from '../../soy-yo/services/soy-yo.service';
import { SecurityService } from '../services/security.service';

@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss']
})
export class BasicComponent implements OnInit, OnDestroy {

  @ViewChild('warning1') warning1: ElementRef;
  @ViewChild('warning0') warning0: ElementRef;
  @ViewChild('terms') terms: ElementRef;
  backRoute = '/app/services';
  nextRoute = '/app/address';
  wowDisplay = false;
  isTimeout = false;

  form: FormGroup;
  errorMessage: string = '';
  isNextButtonEnabled = false;
  isLoading = false;
  timer;
  hasValidationPerson = false;
  canContinue = true;
  reload = false;

  public cameraPermissionStatus: string = '';

  constructor(private router: Router,
              private title: Title,
              private securityService: SecurityService,
              private soyYoService: SoyYoService,
    ) {
      this.title.setTitle('Onboarding Digital | Datos Básico');
      this.form = new FormGroup({
        fullname: new FormControl('', Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(35),
        ])),
        identificationType: new FormControl('', Validators.required),
        identification: new FormControl('', Validators.compose([
          Validators.required,
          Validators.min(100000),
          Validators.max(10000000000),
        ])),
        phone: new FormControl('', Validators.compose([
          Validators.required,
          Validators.min(3000000000),
          Validators.max(3999999999),
        ])),
        email: new FormControl('', Validators.compose([
          Validators.required,
          Validators.email,
        ])),
        birthday: new FormControl('', Validators.compose([
          Validators.required,
          this.mustBe18YearsOrOlder // tu validador personalizado
        ])),
        cityBorn: new FormControl('', Validators.compose([
          Validators.required,
        ])),
        authorization: new FormControl(false, Validators.compose([
          Validators.requiredTrue
        ])),
      });  
      this.timer = setTimeout(() => {
        this.isTimeout = true;
      }, 20000);
    }

    mustBe18YearsOrOlder(control: AbstractControl): { [key: string]: boolean } | null {
      if (control.value !== undefined && control.value !== null) {
        const dateValue = new Date(control.value);
        const now = new Date();
        const minimumAge = new Date();
        minimumAge.setFullYear(now.getFullYear() - 18);
    
        if (dateValue > minimumAge) {
          // Si la fecha del control es menor que la fecha mínima (18 años atrás), retorna un error
          return { 'under18': true };
        }
      }
      return null; // si no hay problema, retorna null
    }    

  async ngOnInit() {
    const route = localStorage.getItem('route');
    const currentUrl = this.router.url;
    if(route && currentUrl !== route) {
      await this.router.navigateByUrl(route);
    } else {
      await this.securityService.getTypedPersonData()
        .subscribe({
          next: async (response: any) => {
            if(response) {
              // console.log(response);
              const step = Number(response?.checkpoint?.split('_')[response?.checkpoint?.split('_')?.length - 1]);
              this.form.patchValue({
                fullname: response.fullname,
                identificationType: response.identificationType,
                identification: response.identification,
                phone: response.phone,
                email: response.email,
                birthday: response.birthday,
                cityBorn: response.cityBorn,
              });
              if(step > 3) {
                this.form.patchValue({
                  authorization: true,
                });
                
                this.form.get('fullname')?.disable();
                this.form.get('identificationType')?.disable();
                this.form.get('identification')?.disable();
                this.form.get('phone')?.disable();
                this.form.get('email')?.disable();
                this.form.get('birthday')?.disable();
                this.form.get('cityBorn')?.disable();
                this.form.get('authorization')?.disable();
                if(step === 6) {
                  this.canContinue = false;
                }
                this.hasValidationPerson = true;
              }
            }
          },
          error: async (error: any) => {
            // console.log(error.status);
            // console.log(error);
          }
        });
    }
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
    clearInterval(this.timerCamera);
  }

  //@ViewChild('videoElement') videoElement: ElementRef;
  //video: any;
  timerCamera;

  async initCamera() {
    let config = { video: true, audio: false };
    let stream: any = null;
    try {
      stream = await navigator.mediaDevices.getUserMedia(config);
      // console.log('PERMISO OK');
      // const tracks = stream.getTracks();

      // tracks.forEach(function(track) {
      //     console.log('stop', track);
      //     track.stop();
      // });

      //this.video.srcObject = stream;
    } catch (err) {
      console.error('PERMISO FAIL');
      // Aquí debes manejar el error, puedes mostrar una alerta o un mensaje en el 
      // DOM para que el usuario habilite la cámara.
      // También puedes volver a intentar iniciar la cámara después de un tiempo:
    }
  }

  async checkCameraPermission() {
    try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        // console.log(videoDevices);
        if (videoDevices.length > 0) {
            // console.log('PERMISO OK 1', new Date());
        } else {
            // console.log('PERMISO FAIL 1', new Date());
        }
    } catch (err) {
        console.error('ERROR:', err);
    }
  }


  async checkCameraPermissionxxx() {
    
    try {
      // console.log('checkCameraPermission');
      // let params = { video: true };
      // navigator.mediaDevices.getUserMedia(params)
      //   .then((stream)=>{
      //       stream.getTracks().forEach((track)=>{
      //         console.log(track);       
      //     });
      //   })
      //   .catch((err)=>{
      //     console.log('catch', 'No hay permiso para acceder a la cámara');
      //   })
      // ;


      // let errorCallback = (error)=> {
      //   if ((error.name == 'NotAllowedError') || (error.name == 'PermissionDismissedError')) {
      //          return false;
      //   }
      // };
      // if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      //    navigator.mediaDevices.getUserMedia({video:true,audio:true})
      //       .then((success)=>{
      //         if(navigator.mediaDevices || navigator.getUserMedia){
      //           let navigator : Navigator;
      //           navigator.permissions.query({name: "camera"})
      //           .then(({state}) => {
      //             if(state == 'granted'){
      //                console.log("permission granted!");            
     
      //             }else if(state == 'denied'){
      //               console.log("permission denied!");                 
      //             }
      //           })
      //         }
      //    },errorCallback);
      //  }



      // Consulta el estado del permiso para acceder a la cámara
    //   const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

    //   if (permissionStatus.state === 'granted') {
    //     this.cameraPermissionStatus = 'Permiso concedido';
    //   } else if (permissionStatus.state === 'prompt') {
    //     this.cameraPermissionStatus = 'Solicitud de permiso pendiente';
    //   } else if (permissionStatus.state === 'denied') {
    //     this.cameraPermissionStatus = 'Permiso denegado';
    //   } else {
    //     this.cameraPermissionStatus = 'Permiso desconocido';
    //   }
    // } catch (err: any) {
    //   this.cameraPermissionStatus = 'Error al verificar el permiso: ' + err.message;
    // }
    } catch(err: any) {
      console.log(err);
    }
  }

  async next() {
    localStorage.setItem('route', this.nextRoute);
    this.router.navigateByUrl(this.nextRoute);
  }

  async back() {
    localStorage.setItem('route', this.backRoute);
    this.router.navigateByUrl(this.backRoute);
  }

  showPreAlertCamera() {
    this.warning0.nativeElement.classList.remove('hidden');
    this.warning0.nativeElement.classList.add('visible');
  }

  async onSubmit(): Promise<void> {

    this.warning0.nativeElement.classList.remove('visible');
    this.warning0.nativeElement.classList.add('hidden');

    if(this.hasValidationPerson) {
      if(!this.canContinue){
        return;
      }
      this.next();
      return;
    }

    this.isLoading = true;
    const identification = this.form.value.identification;
    const email = this.form.value.email;
    const phone = this.form.value.phone;
    const fullname = this.form.value.fullname;
    const identificationType = this.form.value.identificationType;
    const birthday = this.form.value.birthday;
    const cityBorn = this.form.value.cityBorn;
    let documentType = 'CC';
    switch(identificationType) {
      case '1':
        documentType = 'CC';
        break;
      case '2':
        documentType = 'CE';
        break;
      case 1:
        documentType = 'CC';
        break;
      case 2:
        documentType = 'CE';
        break;
      default:
        documentType = 'CC';
        break;
    }
    this.isNextButtonEnabled = false;
    await this.saveData(identificationType, identification, email, phone, fullname, birthday, cityBorn);
    // console.log('información almacenada');
    // this.isLoading = false;

    // if(this.timerCamera) {
    //   clearInterval(this.timerCamera);
    // } else {
    //   this.initCamera();
    //   this.timerCamera = setInterval(() => this.initCamera(), 5000);
    // }
    // return;
    this.isLoading = true;
    const soyYoModel = this.soyYoService;
    soyYoModel.errorTrhowed.subscribe({
      next: async (error: any) => {
        if(error) {
          this.failMessage('106-F: Ha fallado la validación del usuario, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
        }
      }});
    try {
      await soyYoModel.init();
      await soyYoModel.checkingCustomerRegister(documentType, identification);
      await this.createTransaction(soyYoModel, documentType, identification);
      if(!soyYoModel.isCustomerRegistred()) {
          const response = await soyYoModel.registerUser(email, phone, documentType, identification);
          // console.log('response', response);
          if(!response) {
            this.failMessage('106-D: Ha fallado la validación del usuario, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
            // console.log('response exit', response);
            return;
          }
          switch(soyYoModel.getUserRegisterDataCode()) {
            case ENROLLMENT_REGISTER_DATA_CODES.SUCCESS:
              await soyYoModel.captureFaceProcess(email, phone, documentType, identification);
              switch(soyYoModel.getCaptureBiometricFaceData()) {
                case ENROLLMENT_BIOMETRIC_PHOTO.SUCCESS:
                  await soyYoModel.activatedUser();
                  soyYoModel.getActivatedUserData();
                  if(soyYoModel.isActivatedUser()) {
                    await this.getTransaction(soyYoModel);
                  } else {
                    this.failMessage('106-A: Ha fallado la activación del usuario, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
                  }
                  break;
                case ENROLLMENT_BIOMETRIC_PHOTO.INSUFICIENT_VALIDATION:
                  await soyYoModel.enrollmentDocumentValidate(email, phone, documentType, identification);
                  if(!soyYoModel.isDocumentValidated()) {
                    switch(soyYoModel.getDocumentValidatedData()?.code) {
                      case ENROLLMENT_DOCUMENT_VALIDATE_CODES.FAIL_PROCESS_CAPTURE:
                        this.failMessage('107: Falló el proceso de captura de imagen, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
                        break;
                      case ENROLLMENT_DOCUMENT_VALIDATE_CODES.MAX_INTENT_TRIES:
                        this.failMessage('108: Ha superado el número de intentos, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
                        break;
                      default:
                        this.failMessage('109: Verifique su conexión e intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
                        break;
                      }
                  } else {
                    await soyYoModel.activatedUser();
                    soyYoModel.getActivatedUserData();
                    if(soyYoModel.isActivatedUser()) {
                      await this.getTransaction(soyYoModel);
                    } else {
                      this.failMessage('109-A: Ha fallado la activación del usuario, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
                    }
                  }
                  break;
                case ENROLLMENT_BIOMETRIC_PHOTO.FAIL_PHOTO:
                  this.failMessage('110: Falló la captura de la foto, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
                  break;
                case ENROLLMENT_BIOMETRIC_PHOTO.MAX_INTENT_TRIES:
                  this.failMessage('111: Superó el número de intentos, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
                  break;
                default:
                  this.failMessage('112: Verifique su conexión e intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
                  break;
              }
              break;
            case ENROLLMENT_REGISTER_DATA_CODES.INCORRECT_DATA:
              this.failMessage('101: La información ingresada no es correcta, corrija e intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
              break;
            case ENROLLMENT_REGISTER_DATA_CODES.USER_ALREADY_REGISTER:
              this.failMessage('102: La información es coincidente con una ingresada, corrija e intente de nuevo o acérquese a una oficina...');
              break;
            default:
              this.failMessage('103: Verifique su conexión e intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
              break;
          }

      } else {
        await this.authenticatedUserAndGetTransactionListData(soyYoModel, documentType, identification);
      }

    } catch (error) {
      this.failMessage('106-E: Ha fallado la validación del usuario, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
      // console.log('catch');
    }


  }

  private async saveData(identificationType: any, identification: any, email: any, phone: any, fullname: any, birthday: any, cityBorn: any) {
    return new Promise((resolve, reject) => {
      this.securityService.setTypedPersonData(identificationType, identification, email, phone, fullname, birthday, cityBorn)
        .subscribe({
          next: async (response: any) => {
            resolve(response);
          },
          error: async (error: any) => {
            // console.log(error);
            reject(error);
          }
        });
      });
  }

  async authenticatedUserAndGetTransactionListData(soyYoModel: SoyYoService, documentType, identification) {
    await soyYoModel.authenticateUser(documentType, identification);
      if(!soyYoModel.isUserAuthenticated()) {
        switch(soyYoModel.getAuthenticatedCode()) {
          case AUTHENTICATED_USER_CODES.USER_NOT_MATCH:
            // console.log(soyYoModel.getAuthenticatedUserData());
            if(soyYoModel.getAuthenticatedUserData()?.data?.description === "No se puede abrir la cámara, dé permiso o reintente la captura") {
              this.failMessage('116A: No se puede acceder a la cámara para iniciar su validación biométrica, por favor asigne los permisos e intente nuevamente...', true);
            } else {
              this.failMessage('116: Autenticación incorrecta, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
            }
            break;
          case AUTHENTICATED_USER_CODES.MAX_INTENT_TRIES:
            this.failMessage('117: Superó el número de intentos para el día de hoy, acérquese a una oficina o comuníquese con su asesor...');
            break;
          default:
            console.log("errorrrrrrr", soyYoModel.getAuthenticatedCode());
            this.failMessage('118: Verifique su conexión e intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
            break;
        }
      } else {
        await this.getTransaction(soyYoModel);
      }
  }

  private async createTransaction(soyYoModel: SoyYoService, documentType: string, identification: any) {
    await soyYoModel.createTransaction(documentType, identification);
    if (!soyYoModel.isTransactionCreated()) {
      this.failMessage('119: No se pudo crear la transacción, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
    }
  }

  private async getTransaction(soyYoModel: SoyYoService) {
    await soyYoModel.checkTramitesAuthorizationGetInformation();
    if (!soyYoModel.isTramitesAuthorizationGetInformation()) {
      this.failMessage('120: No es posible continuar porque rechazó la autorización, intente de nuevo y acéptela. En caso de ser necesario, acérquese a una oficina o comuníquese con su asesor...');
    } else {
      await soyYoModel.getTramitesListInformation();
      const responseSoyYoData = soyYoModel.getListInformationData();
      this.securityService.setValidateData(responseSoyYoData)
      .subscribe({
        next: (response) => {
        }
      });    

      if (!soyYoModel.isListInformationGetting()) {
        this.failMessage('121: Verifique su conexión e intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
      } else {
        if(responseSoyYoData?.data?.transactionValidation[0]?.result) {
          const result = JSON.parse(responseSoyYoData.data.transactionValidation[0].result);
          if(result.results[0]?.datos_tsti.length > 0) {
            // console.warn('120: No es posible continuar con su vinculación por este canal. Por acérquese a una oficina o comuníquese con su asesor...');
            this.failMessage('122: No es posible continuar con su vinculación por este canal. Por acérquese a una oficina o comuníquese con su asesor...');
            this.canContinue = false;
            this.hasValidationPerson = true;
          } else {
            // console.warn('OKKKK 1111');
            this.wow();
          }
        } else {
          // console.warn('OKKKK 2222');
          this.wow();
        }
      }
    }
  }

  failMessage(message, relaod = false) {
    this.reload = relaod;
    this.errorMessage = message;
    this.isLoading = false;
    this.isNextButtonEnabled = false;
  }

  wow() {
    this.securityService.setNewPoints('validation', 500)
      .subscribe();
    this.wowDisplay = true;
    this.isLoading = false;
  }

  retry() {
    this.errorMessage = '';

  }

  skip() {
    const identification = this.form.value.identification;
    const email = this.form.value.email;
    const phone = this.form.value.phone;
    const fullname = this.form.value.fullname;
    const identificationType = this.form.value.identificationType;
    const birthday = this.form.value.birthday;
    const cityBorn = this.form.value.cityBorn;
    this.isNextButtonEnabled = false;
    this.saveData(identificationType, identification, email, phone, fullname, birthday, cityBorn);
    this.wow();
  }

  testxxx() {
    const data = {
      "code": "F102",
      "message": "Transaction found",
      "type": "Success",
      "data": {
        "procedureId": 109,
        "expirationDate": "2023-06-22T09:17:18.000Z",
        "creationDate": "2023-06-21T21:17:18.000Z",
        "state": "AUTHORIZED",
        "userId": 6674,
        "identificationNumber": "80067257",
        "documentType": "CC",
        "levelIdentity": 2,
        "transactionValidation": [
          {
            "validationId": 11,
            "validatorName": "Legal Check",
            "result": "{}",
            "transactionValidationStatusId": 1,
            "transactionValidationStatus": "REGISTERED"
          }
        ],
        "documents": [],
        "failedRedirectUrl": "",
        "successRedirectUrl": "",
        "data": "{\"fieldsData\":[{\"fieldId\":14,\"fieldName\":\"Tipo de documento\",\"fieldValue\":\"CC \"},{\"fieldId\":13,\"fieldName\":\"Número de documento\",\"fieldValue\":\"80067257\"},{\"fieldId\":1,\"fieldName\":\"Primer nombre\",\"fieldValue\":\"JOHAN\"},{\"fieldId\":2,\"fieldName\":\"Segundo nombre\",\"fieldValue\":\"GABRIEL\"},{\"fieldId\":3,\"fieldName\":\"Apellidos\",\"fieldValue\":\"RINCON ALFONSO\"},{\"fieldId\":6,\"fieldName\":\"Fecha de expedición\",\"fieldValue\":\" 11/06/1997 \"},{\"fieldId\":7,\"fieldName\":\"Lugar de expedición\",\"fieldValue\":\"BOGOTA DC - CUNDINAMARCA \"},{\"fieldId\":18,\"fieldName\":\"Numero de celular\",\"fieldValue\":\"+57 3168655624\"},{\"fieldId\":19,\"fieldName\":\"Correo\",\"fieldValue\":\"anivelmundial@hotmail.com\"}]}",
        "userName": "JOHAN GABRIEL RINCON ALFONSO",
        "attribute": "{}",
        "procedureType": 1,
        "originatorNames": "",
        "originatorLastnames": ""
      },
      "traceId": "ac229961-e338-5629-8dd5-8f2396d9220b"
    };

    this.securityService.setValidateData(data as any)
      .subscribe({
        next: (response) => {
          // console.log(response);
        }
      });

  }

  setLocation($event: number|null) {
    this.form.get('cityBorn')!.setValue($event);
  }

  async testingCamera() {
    this.warning0.nativeElement.classList.remove('visible');
    this.warning0.nativeElement.classList.add('hidden');
    const that = this;
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          // const video: HTMLVideoElement = this.videoElement.nativeElement;
          // video.srcObject = stream;
          this.onSubmit();
        })
        .catch(error => {
          this.warning1.nativeElement.classList.remove('hidden');
          this.warning1.nativeElement.classList.add('visible');   
        });
    } else {
      console.error("getUserMedia no es soportado en este navegador.");
      this.warning1.nativeElement.classList.remove('hidden');
      this.warning1.nativeElement.classList.add('visible');   
}
  }

  allowCamera() {

  }

  hiddeAlert() {
    this.warning1.nativeElement.classList.remove('visible');
    this.warning1.nativeElement.classList.add('hidden');
  }

  showTerms() {
    this.terms.nativeElement.classList.remove('hidden');
    this.terms.nativeElement.classList.add('visible');
  }

  hiddeTerms() {
    this.terms.nativeElement.classList.remove('visible');
    this.terms.nativeElement.classList.add('hidden');
  }

}
