<div class="overlay">
</div>

<div class="centrado">
    <img src="assets/logobsc.png" class="logo" alt="Logo Fincomercio" style="width: 120px; height: 120px;">
    <br>
    <span class="small">{{ error }}</span>
    <br>
    <a [href]="url" target="_blank">
        Si deseas contactar a tu asesor, haz clic aquí <img src="assets/whatsapp.png" alt="whatsapp" >
    </a>
    
</div>