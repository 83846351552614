import { AbstractControl, ValidatorFn } from '@angular/forms';

export function beforeDateValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Opcional, para ignorar la hora y comparar solo la fecha
    const selectedDate = new Date(control.value);

    return selectedDate < today ? null : { 'beforeDate': { value: control.value } };
  };
}