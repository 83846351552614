<div class="title-container body-blue">
    <div class="back" (click)="back()">Regresar</div>
    <div class="logo-box">
      <img src="assets/logobsc.png" class="logo" alt="Logo Fincomercio" />
    </div>
  </div>
  <div class="body-container">
    <div class="container">
      <div class="row">
        <div *ngIf="!isOtpValidated" class="col d-flex justify-content-center title">
            Firma tu vinculación
        </div>
        <div *ngIf="isOtpValidated" class="col d-flex justify-content-center title">
            La vinculación ya ha sido firmada
        </div>
      </div>
      <div *ngIf="!isOtpValidated" class="row">
        <div class="col text-center">
              
                    <form [formGroup]="form">
                        <div class="mb-3">
                            <label for="address" class="form-label">Hemos enviado a tu número celular registrado un código de seis (6) digitos, ingresalo aquí para firmar tu vinculación:</label>
                            <input formControlName="code" type="text" class="form-control" id="address" aria-describedby="addressHelp">
                            <!-- <div id="emailHelp" class="form-text">Es la mejor forma de identificarte.</div> -->
                        </div>  
                      </form>
                      <div>Podrá reenviar el código en {{ seconds }} segundos...</div>
                      <input type="button" class="btn btn-success" value="Reenviar código" (click)="resendCode()" [disabled]="seconds > 0">

        </div>
      </div>
  
      <div>
        <button [disabled]="form.invalid && !isOtpValidated"
          type="button"
          class="btn btn-success"
          (click)="checkCode()"
          style="margin-top: 2em"
        >
          Continuar
        </button>
      </div>
    </div>
  </div>
  <app-wow *ngIf="wowDisplay" (completed)="next()"></app-wow>