<hr>
<div class="text-qr">
    Si aún no estás desde tu teléfono, por favor escanea el QR con la cámara de tu celular.
    <br>
<img [src]="consultor?.qrImage" alt="Código consultor" >
<br>
<span (click)="share()">Asesor: {{ consultor?.name | titlecase }}</span><br>
<span (click)="share()">OnBoarding Digital Gamificada</span><br>
<span (click)="share()">{{ getFullUrl() }}</span><br>
<img (click)="share()" class="share" src="assets/share.png"><br>
</div>
