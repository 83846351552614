<div class="card d-flex align-items-center" [ngClass]="{'bg-selected' : state === 'large'}" [@grow]="state" (click)="toggleSize()">
    <div class="row w-100" >
        <div class="col-4 d-flex align-items-center justify-content-center">
            <img [src]="icon" style="height: 50%;" />
        </div>
        <div class="col-6 d-flex align-items-center">
            <div class="fluid-container w-100">
                <div class="row">
                    <div class="col text-start small-subtitle">
                        {{ firstLine }}
                    </div>
                </div>
                <div class="row">
                    <div class="col text-start subtitle">
                        {{secondLine}}
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-2 d-flex align-items-center" *ngIf="link.length > 3">
            <img src="assets/corp/plus.png" (click)="goToVideo()"/>
        </div> -->
    </div>
</div>