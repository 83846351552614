import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ListService } from '../../services/list.service';

@Component({
  selector: 'app-fonpen',
  templateUrl: './fonpen.component.html',
  styleUrls: ['./fonpen.component.scss']
})
export class FonpenComponent implements OnInit{

  elements: any[] = [];
  filtered: any[] = [];
  form: FormGroup;
  timer: any;
  isTimeout = false;
  @Input() defaultValue: any = null;
  @Output() valueState = new EventEmitter<number | null>();
  @ViewChild('element') element!: ElementRef;
  isValid = false;

  constructor(private listService: ListService) {
    this.form = new FormGroup({
      element: new FormControl('', [Validators.required]),
    });
    this.timer = setTimeout(() => {
      this.isTimeout = true;
    }, 5000);
   }

  ngOnInit(): void {
    this.listService.getPensionados()
    .subscribe({
      next: (response: any) => {
        this.elements = response.list;
        if(this.defaultValue) {
          this.setDefaultValue(this.defaultValue);
        }
    },});
  }

  filter(event: any) {
    this.isValid = false;
    const query = event.target.value.toLowerCase();
    this.filtered = this.elements.filter(location =>
      location.name.toLowerCase().includes(query)
    );
  }

  select(id: number, name: string) {
    this.form.get('element')!.setValue(name);
    this.isValid = true;
    this.filtered = [];
    this.valueState.emit(id);
  }

  esc() {
    this.filtered = [];
    this.isValid = false;
    this.form.get('element')!.reset();
    this.valueState.emit(null);
  }

  makeInvalid() {
    this.isValid = false;
    this.valueState.emit(null);
  }

  setDefaultValue(defaultValue) {
    this.form.get('element')!.setValue(defaultValue);
    this.isValid = true;
    const objs = this.elements.filter(element => element.id == defaultValue)[0];
    if(objs){
      this.element.nativeElement.value = objs.name;
    }
  }

  validateBlur() {
    setTimeout( () => {
      if(!this.isValid) {
        this.esc();
      }
    }, 1000);
  }

}
