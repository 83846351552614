<div class="title-container body-blue">
    <div class="back" (click)="back()">Regresar</div>
    <img src="assets/logobsc.png" class="logo" alt="Logo Fincomercio" />
  </div>
  <div class="body-container">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center title">
            Algo más de información...
        </div>
      </div>
  
      <div class="row">
        <div class="col text-start">
              
                    <form>
                        <div class="mb-3">
                            <label for="address" class="form-label">¿Cuál es tu salario?</label>
                            <input type="text" class="form-control" id="address" aria-describedby="addressHelp">
                            <!-- <div id="emailHelp" class="form-text">Es la mejor forma de identificarte.</div> -->
                        </div>  
                        <div class="mb-3">
                            <label for="address" class="form-label">Nombre de la empresa donde trabajas</label>
                            <input type="text" class="form-control" id="address" aria-describedby="addressHelp">
                            <!-- <div id="emailHelp" class="form-text">Es la mejor forma de identificarte.</div> -->
                        </div>  
                        <div class="mb-3">
                            <label for="address" class="form-label">Cargo</label>
                            <input type="text" class="form-control" id="address" aria-describedby="addressHelp">
                            <!-- <div id="emailHelp" class="form-text">Es la mejor forma de identificarte.</div> -->
                        </div>  
                        <div class="mb-3">
                          <label for="ocupation" class="form-label">¿Cuál es tu nivel de estudios?</label>
                          <select class="form-select" aria-label="Ocipación" id="ocupation">
                            <option selected>Seleccione...</option>
                            <option value="1">Bachiller</option>
                            <option value="2">Pregrado</option>
                            <option value="3">Postgrado</option>
                          </select>
                          <!-- <div id="emailHelp" class="form-text">Es la mejor forma de identificarte.</div> -->
                        </div>
                        <div class="mb-3">
                          <label for="ocupation" class="form-label">¿Cuál es tu estado civil?</label>
                          <select class="form-select" aria-label="Ocipación" id="ocupation">
                            <option selected>Seleccione...</option>
                            <option value="1">Soltero</option>
                            <option value="2">Casado</option>
                            <option value="3">Unión Libre</option>
                            <option value="3">Separado</option>
                          </select>
                          <!-- <div id="emailHelp" class="form-text">Es la mejor forma de identificarte.</div> -->
                        </div>
                        <div class="mb-3">
                          <label for="department" class="form-label">¿Eres mujer cabeza de familia?</label>
                          <select class="form-select" aria-label="Departamento" id="department">
                            <option selected value="1">No</option>
                            <option value="2">Sí</option>
                          </select>
                          <!-- <div id="emailHelp" class="form-text"></div> -->
                        </div>
                      </form>


        </div>
      </div>
  
      <div>
        <button
          type="button"
          class="btn btn-success"
          (click)="wow()"
          style="margin-top: 2em"
        >
          Continuar
        </button>
      </div>
    </div>
  </div>
  <app-wow *ngIf="wowDisplay" (completed)="next()"></app-wow>