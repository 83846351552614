<div class="title-container body-blue">
    <div class="back" (click)="back()">Regresar</div>
    <div class="logo-box">
      <img src="assets/logobsc.png" class="logo" alt="Logo Fincomercio" />
    </div>
  </div>
  <div class="body-container">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center title">
            ¡Gana más puntos!
        </div>
      </div>
  
      <div class="row">
        <div class="col">
              
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mb-3 text-start">
                <label for="hasPublicJobManager" class="form-label">¿Administras recursos públicos o ejerces un cargo importante?</label>
                <select class="form-select" id="hasPublicJobManager" formControlName="hasPublicJobManager">
                    <option value="">Selecciona...</option>
                    <option value="no">No</option>
                    <option value="yes">Sí</option>
                </select>
            </div>
            <div class="mb-3 text-start">
                <label for="hasGreenCard" class="form-label">¿Tienes Green Card?</label>
                <select class="form-select" id="hasGreenCard" formControlName="hasGreenCard">
                    <option value="">Selecciona...</option>
                    <option value="no">No</option>
                    <option value="yes">Sí</option>
                </select>
            </div>
            <div class="mb-3 text-start">
                <label for="hasNothAmericanNationality" class="form-label">¿Tus padres tienen nacionalidad norteamericana?</label>
                <select class="form-select" id="hasNothAmericanNationality" formControlName="hasNothAmericanNationality">
                    <option value="">Selecciona...</option>
                    <option value="no">No</option>
                    <option value="yes">Sí</option>
                </select>
            </div>
            <div class="mb-3 text-start">
                <label for="hasForeignTransactions" class="form-label">¿Realizas transacciones en el extranjero?</label>
                <select class="form-select" id="hasForeignTransactions" formControlName="hasForeignTransactions">
                    <option value="">Selecciona...</option>
                    <option value="no">No</option>
                    <option value="yes">Sí</option>
                </select>
            </div>
            <div class="mb-3 text-start">
                <label for="hasForeignAccounts" class="form-label">¿Tienes cuentas en el extranjero?</label>
                <select class="form-select" id="hasForeignAccounts" formControlName="hasForeignAccounts">
                    <option value="">Selecciona...</option>
                    <option value="no">No</option>
                    <option value="yes">Sí</option>
                </select>
            </div>
            <div *ngIf="form.get('hasForeignAccounts')!.value == 'yes' " class="mb-3 text-start">
                <label for="foreignAccountBank" class="form-label">Banco de la cuenta extranjera</label>
                <input type="text" class="form-control" id="foreignAccountBank" formControlName="foreignAccountBank" />
            </div>
            <div class="mb-3 text-start" *ngIf="form.get('hasForeignAccounts')!.value == 'yes'" >
                <label for="foreignAccountBankNumber" class="form-label">Número de cuenta bancaria extranjera</label>
                <input type="text" class="form-control" id="foreignAccountBankNumber" formControlName="foreignAccountBankNumber" />
            </div>
            <div class="mb-3 text-start" *ngIf="form.get('hasForeignAccounts')!.value == 'yes'">
                <label for="foreignAccountCurrency" class="form-label">Moneda de la cuenta extranjera</label>
                <input type="text" class="form-control" id="foreignAccountCurrency" formControlName="foreignAccountCurrency" />
            </div>
            <div class="mb-3 text-start" *ngIf="form.get('hasForeignAccounts')!.value == 'yes'">
                <label for="foreignAccountType" class="form-label">Tipo de cuenta extranjera</label>
                <input type="text" class="form-control" id="foreignAccountType" formControlName="foreignAccountType" />
            </div>
            <div class="mb-3 text-start" *ngIf="form.get('hasForeignAccounts')!.value == 'yes'">
                <label for="foreignAccountCountry" class="form-label">País de la cuenta extranjera</label>
                <input type="text" class="form-control" id="foreignAccountCountry" formControlName="foreignAccountCountry" />
            </div>
            <div class="mb-3 text-start" *ngIf="form.get('hasForeignAccounts')!.value == 'yes'">
                <label for="foreignAccountCity" class="form-label">Ciudad de la cuenta extranjera</label>
                <input type="text" class="form-control" id="foreignAccountCity" formControlName="foreignAccountCity" />
            </div>
            <button
            [disabled]="!form.valid"
            type="submit"
            class="btn btn-success mb-5"
            style="margin-top: 2em"
          >
            Continuar
          </button>
          </form>
      </div>
    </div>
  </div>
  <app-wow *ngIf="wowDisplay" (completed)="next()"></app-wow>