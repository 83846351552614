import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LocalstorageService } from '../services/localstorage.service';

@Component({
  selector: 'app-congrats',
  templateUrl: './congrats.component.html',
  styleUrls: ['./congrats.component.scss']
})
export class CongratsComponent implements OnInit {

  backRoute = '/app/sign';
  nextRoute = '/app/balance';
  wowDisplay = false;
  phone;

  constructor(private router: Router,
              private title: Title,
              private localstorageService: LocalstorageService,
    ) {
      this.title.setTitle('Onboarding Digital | Felicitaciones');
      this.phone = this.localstorageService.get('phone');
    }

  async ngOnInit() {
    const route = localStorage.getItem('route');
    const currentUrl = this.router.url;
    if(route && currentUrl !== route) {
      await this.router.navigateByUrl(route);
    }
  }

  async next() {
    localStorage.setItem('route', this.nextRoute);
    this.router.navigateByUrl(this.nextRoute);
  }

  async back() {
    localStorage.setItem('route', this.backRoute);
    this.router.navigateByUrl(this.backRoute);
  }

  wow() {
    this.wowDisplay = true;
  }

}
