<div class="overlay">
</div>

<div class="centrado">
    Por favor espere... <br> Estamos validando su información...
    <br>
    <div id="loading-container">
        <div class="loading-circle"></div>
        <div class="loading-circle"></div>
        <div class="loading-circle"></div>
        <div class="loading-circle"></div>
      </div>      
</div>