import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SecurityService } from '../services/security.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit {

  backRoute = '/';
  nextRoute = '/app/basic';
  wowDisplay = false;
  activeServices: string[] = [];

  constructor(private router: Router,
              private title: Title,
              private securityService: SecurityService,
    ) {
    }

  async ngOnInit() {
    setTimeout(() => {
      this.title.setTitle('Onboarding Digital | Servicios');
    }, 1000);
    this.title.setTitle('Onboarding Digital | Servicios');
    const route = localStorage.getItem('route');
    const currentUrl = this.router.url;
    if(route && currentUrl !== route) {
      await this.router.navigateByUrl(route);
    }
    this.securityService.getServices()
      .subscribe({
        next: (response: any) => {
          this.activeServices = response.activeServices;
      },});
  }

  async next() {
    localStorage.setItem('route', this.nextRoute);
    this.router.navigateByUrl(this.nextRoute);
  }

  async back() {
    localStorage.setItem('route', this.backRoute);
    this.router.navigateByUrl(this.backRoute);
  }

  wow() {
    // aqui van los puntos de control
    this.securityService.setNewPoints('services', 500)
      .subscribe();
    this.wowDisplay = true;
  }

  selectService(event: string, service: string) {
    if(event === 'large') {
      this.activeServices.push(service);
    } else {
      this.activeServices = this.activeServices.filter(item => item !== service);
    }
    this.securityService.setActiveServices(this.activeServices)
      .subscribe();
  }

}
